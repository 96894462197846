import { Box, Button, Grid, Typography } from "@mui/material";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import { useTranslation } from "react-i18next";
import OrderListOIButton from "../UI/OrderListOIButton/OrderListOIButton";
import { PageHeading } from "../UI/Typography/SharedTypography";
import {
  OverviewBoxStyled,
  OverviewButtonBoxStyled,
  OverviewGridContainerStyled,
} from "./Shared";

const OrdersGrid = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeading>{t("overview.orders")}</PageHeading>
      <OverviewGridContainerStyled>
        <Grid item md={3} xs={12}>
          <OverviewBoxStyled>
            <OverviewBoxHeadingStyled>
              {t("overview.scheduleNewDelivery")}
            </OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled>
              <Button variant="contained">{t("overview.schedule")}</Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={3} xs={12}>
          <OverviewBoxStyled>
            <Box>
              <OverviewBoxHeadingStyled>
                {t("overview.orderIntakeCollectWidgetTitle")}
              </OverviewBoxHeadingStyled>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ lineHeight: "14px" }}
              >
                {t("orderIntake.collect.generateQrCodeCaption")}
              </Typography>
            </Box>
            <OverviewButtonBoxStyled>
              <Button variant="contained">
                {t("overview.generateQrCode")}
              </Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled>
            <OverviewBoxHeadingStyled>
              {t("overview.ordersByStatus")}
            </OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled>
              <OrderListOIButton>{t("overview.upcoming")}</OrderListOIButton>
              <Button variant="contained">{t("overview.ongoing")}</Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default OrdersGrid;
