import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  CardContent,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { confirmation, confirmationError } from "assets/img/images";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useActions } from "hooks/useActions";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ConfirmationText,
  SecondaryColorHeading,
} from "../UI/Typography/DeliveryOI";
import { MainColorHeading } from "../UI/Typography/SharedTypography";

const ConfirmationCaptionHeading = styled((props) => (
  <Typography variant="caption" {...props} />
))(() => ({}));

const ConfirmationBox = styled((props) => <Box marginBottom={2} {...props} />)(
  () => ({})
);

const StatusIcon = ({ isError }) => {
  return isError ? (
    <ErrorIcon fontSize="small" color="error" />
  ) : (
    <CheckCircleIcon fontSize="small" color="success" />
  );
};

const MobileTable = ({ order }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
      <Box>
        <StatusIcon isError={order.isError} />
      </Box>
      <ConfirmationBox>
        <ConfirmationCaptionHeading>
          {t("orderIntake.materialOrder.load")}
        </ConfirmationCaptionHeading>
        <Typography>{order.truckCapacity.capacity}</Typography>
      </ConfirmationBox>

      <ConfirmationBox>
        <ConfirmationCaptionHeading>
          {t("orderIntake.materialOrder.date")}
        </ConfirmationCaptionHeading>
        <Typography>
          <FormatDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            timeStart={order.deliveryDate}
          />
        </Typography>
        <Typography>{`${order.defaultDeliveryWindow.defaultEarliestLoadTime} - ${order.defaultDeliveryWindow.defaultLatestLoadTime}`}</Typography>
      </ConfirmationBox>

      <ConfirmationBox>
        <ConfirmationCaptionHeading>
          {t("orderIntake.confirmationScreen.additionalInfo")}
        </ConfirmationCaptionHeading>
        <Typography> {order.driverInstructions}</Typography>
      </ConfirmationBox>

      <ConfirmationBox>
        <ConfirmationCaptionHeading>
          {t("orderIntake.materialOrder.po")}
        </ConfirmationCaptionHeading>
        <Typography> {order.customerReference}</Typography>
      </ConfirmationBox>
    </Box>
  );
};

const OIConfirmation = ({ isCollect }) => {
  const { t } = useTranslation();

  const { setIsConfirmationError } = useActions();
  const isDesktop = useIsDesktop();
  const summary = useSelector((state) => state.orderIntake.summary);
  const tableRows = useSelector((state) => state.orderIntake.tableRows);
  const isConfirmationError = useSelector(
    (state) => state.orderIntake.isConfirmationError
  );

  const sucReq = (
    <>
      <MainColorHeading>
        {t("orderIntake.confirmationScreen.receiveRequestSuccessfully")}
      </MainColorHeading>
      <ConfirmationText>
        {t("orderIntake.confirmationScreen.receiveRequestSuccessfullyText")}
      </ConfirmationText>
      {isCollect && (
        <ConfirmationText>
          {t(
            "orderIntake.confirmationScreen.receiveRequestSuccessfullyTextCollect"
          )}
        </ConfirmationText>
      )}
    </>
  );

  const failReq = (
    <>
      <MainColorHeading>
        {t("orderIntake.confirmationScreen.receiveRequestFailed")}
      </MainColorHeading>
      <Typography>
        {t("orderIntake.confirmationScreen.receiveRequestFailedText")}
      </Typography>
    </>
  );

  useEffect(() => {
    const confirmError = tableRows.some((item) => item.isError);
    setIsConfirmationError(confirmError);
  }, [setIsConfirmationError, tableRows]);

  return (
    <Stack>
      <Box marginBottom={2}>
        <Grid
          container
          sx={{
            border: "1px solid #BBDEB3",
            background: "#FBFFF9",
            borderRadius: "8px",
            padding: 3,
          }}
        >
          <Grid item md={10} xs={12}>
            {isConfirmationError ? failReq : sucReq}
          </Grid>
          <Grid item md={2} xs={12}>
            <img
              src={isConfirmationError ? confirmationError : confirmation}
              alt="We Received Your Request"
              style={{ maxWidth: "100px", float: "right" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom={2}>
        <SecondaryColorHeading>
          {t("orderIntake.confirmationScreen.listOfOrderedItems")}
        </SecondaryColorHeading>
      </Box>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #e7e7e7",
        }}
      >
        {Object.keys(summary).map((material, index) => {
          return (
            <CardContent
              key={index}
              sx={{
                padding: 2,
                paddingBottom: 3,
              }}
            >
              <Box
                sx={{
                  padding: "20px 28px",
                  background: "#F5F5F5",
                }}
              >
                <Typography variant="caption">
                  {t("orderIntake.confirmationScreen.materialLoad")}
                </Typography>
                <Typography>{`${summary[material].total}${t(
                  "capacityTons"
                )} ${material}`}</Typography>
              </Box>
              <TableContainer
                sx={{
                  boxShadow: "none",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table with requested orders"
                >
                  <TableHead>
                    {isDesktop && (
                      <TableRow sx={{ borderBottom: "none" }}>
                        <TableCell></TableCell>
                        {summary[material].orders.length > 1 && (
                          <TableCell align="left">
                            {t("orderIntake.materialOrder.load")}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {t("orderIntake.materialOrder.date")}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {t("orderIntake.confirmationScreen.additionalInfo")}
                        </TableCell>
                        <TableCell align="left">
                          {t("orderIntake.materialOrder.po")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  {summary[material].orders.map((order, _, array) => {
                    return isDesktop ? (
                      <TableBody key={order.orderId}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <StatusIcon isError={order.isError} />
                          </TableCell>
                          {array.length > 1 && (
                            <TableCell align="left">
                              {order.truckCapacity.capacity}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <FormatDate
                              day="2-digit"
                              month="2-digit"
                              year="numeric"
                              timeStart={order.deliveryDate}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {`${order.defaultDeliveryWindow.defaultEarliestLoadTime} - ${order.defaultDeliveryWindow.defaultLatestLoadTime}`}
                          </TableCell>
                          <TableCell align="left">
                            {order.driverInstructions}
                          </TableCell>
                          <TableCell align="left">
                            {order.customerReference}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody key={order.orderId}>
                        <TableRow>
                          <TableCell>
                            <MobileTable order={order} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </CardContent>
          );
        })}
      </Paper>
    </Stack>
  );
};

export default OIConfirmation;
