import { Grid, Paper } from "@mui/material";
import OIAdditionalInfo from "Components/OrderIntake/OIAdditionalInfo.jsx";
import OIOverview from "Components/OrderIntake/OIOverview.jsx";
import OITabsForm from "Components/OrderIntake/OITabsForm.jsx";
import { useSelector } from "react-redux";
import OIConfirmation from "./OIConfirmation";

const OIDesktopLayout = ({ isCollect }) => {
  const isConfirmation = useSelector(
    (state) => state.orderIntake.isConfirmation
  );

  return (
    <Grid container maxWidth="1280px">
      <Grid item xs={12} md={8} sx={{ marginBottom: { xs: 4, md: 0 } }}>
        <Paper sx={{ p: 4 }}>
          {isConfirmation ? (
            <OIConfirmation isCollect={isCollect} />
          ) : (
            <OIOverview isCollect={isCollect} />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
        <Paper sx={{ p: 4, position: "sticky", top: "10px" }}>
          {isConfirmation ? (
            <OIAdditionalInfo isCollect={isCollect} />
          ) : (
            <OITabsForm isCollect={isCollect} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OIDesktopLayout;
