import { createSlice } from "@reduxjs/toolkit";
import { sortByMaterial } from "helpers/helpers";

const initialState = {
  deliveryOptions: [],
  currentOption: {},
  tableRows: [],
  latestOrderId: 0,
  currentTableRowIndex: 0,
  tabsForm: {},
  isConfirmation: false,
  isConfirmationError: false,
  isTabsFormOpen: true,
  summary: {},
  isSummaryOpen: false,
  deliveryDates: [],
  currentMaterial: {},
  isCutOffTime: false,
};

const orderIntakeSlice = createSlice({
  name: "orderIntake",
  initialState,
  reducers: {
    setIsCutOffTime: (state, action) => {
      state.isCutOffTime = action.payload;
    },
    setCurrentMaterial: (state, action) => {
      state.currentMaterial = action.payload;
    },
    setDeliveryDates: (state, action) => {
      state.deliveryDates = action.payload;
    },
    setSummary: (state) => {
      state.summary = state.tableRows.reduce(sortByMaterial, {});
    },
    setIsTabsFormOpen: (state, action) => {
      state.isTabsFormOpen = action.payload;
    },
    setSummaryOpen: (state, action) => {
      state.isSummaryOpen = action.payload;
    },
    setDeliveryOptions: (state, action) => {
      state.deliveryOptions = action.payload;
    },
    setCurrentOption: (state, action) => {
      state.tableRows = [];
      state.currentOption = action.payload;
    },
    setIsConfirmation: (state, action) => {
      state.isConfirmation = action.payload;
    },
    setIsConfirmationError: (state, action) => {
      state.isConfirmationError = action.payload;
    },
    addAnotherDelivery: (state) => {
      state.latestOrderId = state.latestOrderId + 1;
      state.currentTableRowIndex = state.latestOrderId;
      state.tableRows = [
        ...state.tableRows,
        { ...state.tableRows.at(-1), orderId: state.latestOrderId },
      ];
      state.tabsForm = { ...state.tabsForm, orderId: state.latestOrderId };
      state.isTabsFormOpen = true;
    },
    removeTableRow: (state, action) => {
      if (state.currentTableRowIndex === action.payload)
        state.isTabsFormOpen = false;
      state.tableRows = state.tableRows.filter((order) => {
        return order.orderId !== action.payload;
      });
    },
    setTableRow: (state, action) => {
      const foundIndex = state.tableRows.findIndex(
        (order) => order.orderId === state.currentTableRowIndex
      );

      const usedIndex = foundIndex === -1 ? 0 : foundIndex;

      state.tableRows[usedIndex] = {
        ...action.payload,
        orderId: state.currentTableRowIndex,
      };
    },
    setTabsForm: (state, action) => {
      state.currentTableRowIndex = action.payload.orderId || 0;
      state.tabsForm = {
        ...state.tabsForm,
        ...action.payload,
        contractValidityPeriods: {
          ...state.tabsForm.contractValidityPeriods,
          ...action.payload.contractValidityPeriods,
        },
        truckCapacity: {
          ...state.tabsForm.truckCapacity,
          ...action.payload.truckCapacity,
        },
        defaultDeliveryWindow: {
          ...state.tabsForm.defaultDeliveryWindow,
          ...action.payload.defaultDeliveryWindow,
        },
        deliveryDate:
          action.payload.deliveryDate ||
          action.payload.businessDays[
            state.isCutOffTime ? "daysAfterCutOffTime" : "daysBeforeCutOffTime"
          ][0],
      };
    },
    updateRow: (state, action) => {
      const foundIndex = state.tableRows.findIndex(
        (order) => order.orderId === action.payload.orderId
      );

      const usedIndex = foundIndex === -1 ? 0 : foundIndex;

      state.tableRows[usedIndex] = {
        ...action.payload,
      };
    },
  },
});

export const { actions } = orderIntakeSlice;
export default orderIntakeSlice.reducer;
