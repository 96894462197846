import InfoIcon from "@mui/icons-material/Info";
import { Box, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OITableRow from "./OITableRow";

const TableCellStyled = styled(TableCell)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  color: "rgb(84, 112, 140)",
}));

const OITable = ({ isCollect }) => {
  const { t } = useTranslation();
  const tableRows = useSelector((state) => state.orderIntake.tableRows);

  return (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Table size="small" sx={{ minWidth: 600 }} aria-label="delivery table">
        <TableHead
          sx={{
            backgroundColor: "rgb(245, 246, 247)",
          }}
        >
          <TableRow>
            <TableCellStyled>#</TableCellStyled>
            <TableCellStyled>
              {t("orderIntake.materialOrder.date")}
            </TableCellStyled>
            <TableCellStyled>
              {t("orderIntake.materialOrder.type")}
            </TableCellStyled>
            <TableCellStyled>
              {t("orderIntake.materialOrder.load")}
            </TableCellStyled>
            <TableCellStyled>
              <Box display="flex" alignItems="center">
                {isCollect
                  ? t("orderIntake.materialOrder.haulierInfo")
                  : t("orderIntake.driverInstructions")}
                <Tooltip
                  title={
                    isCollect
                      ? t("orderIntake.materialOrder.haulierInfoTooltip")
                      : t("orderIntake.driverInstructionsTooltip")
                  }
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Box>
            </TableCellStyled>
            <TableCellStyled>
              <Box display="flex" alignItems="center">
                {t("orderIntake.materialOrder.po")}
                <Tooltip title={t("orderIntake.materialOrder.poTooltip")}>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Box>
            </TableCellStyled>
            <TableCellStyled></TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((order, index, array) => (
            <OITableRow
              key={order.orderId}
              order={order}
              index={index}
              array={array}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OITable;
