import { Box, Toolbar, Typography } from "@mui/material";
import OIDesktopLayout from "Components/OrderIntake/OIDesktopLayout";
import OIMobileLayout from "Components/OrderIntake/OIMobileLayout";
import BackButton from "Components/UI/BackButton/BackButton.jsx";
import Loader from "Components/UI/Loader/Loader";
import { useActions } from "hooks/useActions.jsx";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled.jsx";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useGetDeliveryOptionsQuery } from "reduxStore/api/deliveryApiSlice.js";

const OrderIntake = ({ isCollect = false }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();
  const { setIsConfirmation, setIsTabsFormOpen } = useActions();

  const {
    isFetching: isFetchingDeliveries,
    isSuccess: isSuccessDeliveries,
    data,
  } = useGetDeliveryOptionsQuery(isCollect ? "collect" : "deliver", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setIsConfirmation(false);
    setIsTabsFormOpen(true);
  }, [setIsConfirmation, setIsTabsFormOpen]);

  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderIntake");

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;

  if (isFetchingDeliveries) return <Loader />;

  if (!data?.length)
    return <Typography sx={{ color: "white" }}>No options!</Typography>;

  return (
    <>
      <Toolbar>
        <BackButton>
          {isCollect
            ? t("overview.orderIntakeCollectWidgetTitle")
            : t("overview.scheduleNewDelivery")}
        </BackButton>
      </Toolbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: { md: 4 },
          pt: { md: 0 },
        }}
      >
        {!isFetchingDeliveries &&
          isSuccessDeliveries &&
          (isDesktop ? (
            <OIDesktopLayout isCollect={isCollect} />
          ) : (
            <OIMobileLayout isCollect={isCollect} />
          ))}
      </Box>
    </>
  );
};

export default OrderIntake;
